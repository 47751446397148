import { FunctionComponent, ReactElement } from "react";

interface TabControllerProps {
    children: any,
    initalIndex: number
}
export const TabController: FunctionComponent<TabControllerProps> = ({children, initalIndex}): ReactElement => {

 
    return (
        <>
             {children[initalIndex]}
        </>
    )
}