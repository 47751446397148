import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import big_foto from '../big_foto.jpg';
import ListGroup from 'react-bootstrap/ListGroup'
import { Skills } from './Skills';
import { Infos } from './Infos';
import { Spinner } from './Spinner';

const Home: FunctionComponent = (): ReactElement => {

    const [loaded, setLoaded] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{setLoaded(true)},1000)
    })

    if(!loaded) {
        return (
          <Spinner />
      )
       }
  return (
        <Container className="container w-100"> 
            <Row>
                <Col md="6">
                    <img src={big_foto} className="img-fluid"></img>
                </Col>
                <Col md="6">
                    <h1>Björn Zschernack, Webentwickler Frontend</h1>
                    <i>Zuletzt aktualisiert am 10. Januar 2023</i>
                    <p>Da ich so meine Schwierigkeiten mit den Backends der Projektbörsen habe, finden Sie hier eine eigene kleine Zusammenstellung meiner Daten. </p>
                    <p>
                Aktuell bin ich zwar (noch) nicht auf der Suche nach einem neuen Projekt, freue mich aber immer über passende Angebote aus dem Bereich Frontendentwicklung.
                Welche Skills für mich in Frage kommen, habe ich unten zusammengefasst.
                    </p>
                    
                </Col>
            </Row>
            <Row>
                <Col>
                <h3 className="innerh3">Infos:</h3>
                <Infos />

                <h3 className="innerh3">Skills:</h3>
                <Skills />
                </Col>
             </Row>   
            <Row>
                <Col>

                <h3 className="innerh3">Kontakdaten:</h3>

                <ListGroup variant="flush">
                    <ListGroup.Item><a href="tel://004915157391259">+49 (0) 151 57 39 12 59</a></ListGroup.Item>
                    <ListGroup.Item><a href="mailto:dergoldbroiler@gmail.com">dergoldbroiler@gmail.com</a></ListGroup.Item>
                    <ListGroup.Item><a href="https://dergoldbroiler.de" target="_blank">https://dergoldbroiler.de</a></ListGroup.Item>
                    <ListGroup.Item>An der Kranzmaar 4,<br/>
                    50968 Köln<br/><br/>

                    Geboren am 24.10.1978 in Halle (Saale).<br/><br/>
                    Ledig, keine Kinder.<br/><br/>
                    Aktueller Wohnort ist Köln.</ListGroup.Item><br/><br/>
                </ListGroup><br/><br/>
                </Col>
            </Row>
        </Container>
    )
}     

export {Home}  