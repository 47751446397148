import { FunctionComponent, ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import Badge  from 'react-bootstrap/Badge';


const Infos: FunctionComponent= (): ReactElement => {


    return (
        <Container className="container w-100 p-0"> 
           
        <Row>
            <Col>
            <Badge bg="info">&euro; 60/h</Badge>
            <Badge bg="info">30 - 40h/Woche verfügbar ab 01.05. 2023</Badge>
            <Badge bg="info">Nur Remote</Badge>
            
            <p>&nbsp;</p>
            </Col>
        </Row>

    </Container>
    )
}     

export {Infos}  