import { FunctionComponent, ReactElement, useState, useEffect} from 'react';

import { SingleEntry } from './SingleEntry';

import Masonry from 'react-masonry-css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ReactEntry} from './ReactEntry';
import { Refs } from '../Frontend/types/Refs';
import { Spinner } from './Spinner';
interface EntriesProps {
  entries: any;
  toggleTab:(entry:Refs) => any;
  paging?: void;
  updatePaging?: any;
  onLiveSearchChange?:any;
  searchValue?: string | number;
  onDateChange?: any;
  dateValue?: Date;
}

const Entries:FunctionComponent<EntriesProps>= ({entries}: EntriesProps): ReactElement => {

  const [loaded, setLoaded] = useState(false);

    const preparedEntries: EntriesProps = entries.map(
      entry => { 
        return <SingleEntry entry={entry}/>
      }
    );


    const breakpointColumnsObj = {
      default: 4,
      1100: 3,
      700: 2,
      500: 1
    };



    useEffect(()=>{
        setTimeout(()=>{setLoaded(true)},1000)
    })

    if(!loaded) {
        return (
          <Spinner />
      )
       }

    return (
        <Container>
          <Row>
            <Col>
              <h2 className="h2_cal_frontend">Referenzen:</h2>
              <p>
                Die im Folgenden aufgeführten Referenzen sind nur aktuelle Auszüge. Im Detail kamen folgende Technologien zum Einsatz: TypeScript, Javascript, React (+React-Bootstap, +React-Masonry-CSS, +React Stripe Elements), Node / Express Backend (REST API) für die JSON Referenzen,
                PHP, WordPress (+Gutenberg), MongoDB für die Speicherung der Referenzen, Firebase Cloud Functions für das Backend-Deployment, Git (Bitbucket) Pipelines, Stripe API.
                Teile des Codes finden sich auch in den folgenden Repos:
                <br/><br/><a href="https://bitbucket.org/goldbroiler/node-mini-rest-api/src/master/" target="_blank">Backend Code auf Bitbucket</a><br/>
                <a href="https://bitbucket.org/goldbroiler/myprofile/src/master/" target="_blank">Code Frontend auf Bitbucket</a><br/><br/>
              </p>
               
     
            </Col>
          </Row>
        

          <Row>
            <Col className="grid">

            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {preparedEntries}
            </Masonry>

            </Col>
          </Row>

          <Row>
            <Col>
             
            </Col>
          </Row>
        </Container>
    )
 
}

export {Entries}

