import { FunctionComponent, ReactElement, useEffect, useState } from 'react';

import './Frontend.css';

import { FetchData } from "../services/entries";

import { TabController } from './TabController/TabController';

import { Tab } from './TabController/Tab';

import { Entries } from './Entries';

import { Header } from './Header';

import {Home} from './Home';
import { Downloads } from './Downloads';
import { Refs } from '../Frontend/types/Refs';
import { Spinner } from './Spinner';
export const Frontend:FunctionComponent = ():ReactElement => {

  const [entries, setEntries] = useState<Refs[]>([]);
  const [tabindex, setTabIndex] = useState<number>(0);
  const [loaded, setLoaded] = useState(false);


  useEffect(() => {
      FetchData().then(
          posts => {
       
            var loadedEntries:Refs[] = posts;
            if(entries.length > 0) {
              loadedEntries = entries.concat(posts) 
            }
            setEntries(loadedEntries);   
            setLoaded(true)
          }
        ).catch(error => { console.log(error.msg)})
    
  }, []);

  const clickIt = (elm) => {
    if(elm) {
      elm.click();
    }
  }

  useEffect(() => {

  switch(location.hash) {
    case '#home':
      setTabIndex(0);
      clickIt(document.getElementById('homelink'));
      break;

    case '#refs':
      setTabIndex(1);
      clickIt(document.getElementById('reflink'));
      break;

    case '#downloads':
        setTabIndex(2);
        clickIt(document.getElementById('downloadlink'));
        break;

     default:
          setTabIndex(0);
          clickIt(document.getElementById('homelink'));
          break;
    }
  
}, []);


   const toggleTab = (newindex) => {

      setTabIndex(newindex);
   }

   
   if(!loaded) {
    return (
      <> 
      <Header toggleTab={toggleTab}/>
       <TabController initalIndex={tabindex}>
          <Tab>
            <Home />
          </Tab>
          <Tab>
            <Spinner />
          </Tab>
          <Tab>
          <Downloads />
          </Tab>
        </TabController> 
      </>
  )
   }


    return (
        <> 
        <Header toggleTab={toggleTab}/>
         <TabController initalIndex={tabindex}>
            <Tab>
              <Home />
            </Tab>
            <Tab>
              <Entries 
                entries={entries} 
                toggleTab={toggleTab} 
                />
            </Tab>
            <Tab>
            <Downloads />
            </Tab>
          </TabController> 
        </>
    )
    
}

