import { FunctionComponent, ReactElement } from 'react';



const Spinner: FunctionComponent= (): ReactElement => {


    return (
      <div className="w-100 text-center"><br/><br/>
       <div className="spinner-border" role="status">
      
       </div></div>
    )
}     

export {Spinner}  