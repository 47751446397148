import { FunctionComponent, ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import Badge  from 'react-bootstrap/Badge';


const Skills: FunctionComponent= (): ReactElement => {


    return (
        <Container className="container w-100 p-0"> 
           
        <Row>
            <Col>
            <Badge bg="info">HTML</Badge>
            <Badge bg="info">CSS</Badge>
            <Badge bg="info">Bootstrap</Badge>
            <Badge bg="info">JavaScript</Badge>
            <Badge bg="info">React</Badge>
            <Badge bg="info">Redux / Redux Toolkit</Badge>
            <Badge bg="info">Docker</Badge>
            <Badge bg="info">TypeScript</Badge>
            <Badge bg="info">Node (Beginner)</Badge>
            <Badge bg="info">Firebase (Beginner)</Badge>
            <Badge bg="info">jQuery</Badge>
            <Badge bg="info">PHP</Badge>
            <Badge bg="info">WordPress</Badge>
            <Badge bg="info">WordPress Plugins</Badge>
            <Badge bg="info">Custom Gutenberg Blocks</Badge>
            <Badge bg="info">Git</Badge>  <Badge bg="info">Git Workflow / Git Actions / Bitbucket Pipelines</Badge>
            <Badge bg="info">Stripe / StripeElements / Stripe Elemens React</Badge>
            <p>&nbsp;</p>
            </Col>
        </Row>

    </Container>
    )
}     

export {Skills}  