import { FunctionComponent, ReactElement } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup  from 'react-bootstrap/ListGroup';


const Downloads:FunctionComponent = (): ReactElement => {



    return (
        <Container>
          <Row>
            <Col>
              <h2 className="h2_cal_frontend">Downloads:</h2>
            </Col>
          </Row>
          <Row>
            <Col>
             <p>

              Ich habe einen aktuellen Projektabriss und den Lebenslauf in einem Dokument zusammengefasst.
              Zuletzt wurde die Seite im Januar 2023 aktualisiert.
             </p>
            </Col>
          </Row>

          <Row>
            <Col>

            <ListGroup className="work">
               <ListGroup.Item>
                   <a href="https://dergoldbroiler.de/profil_bjoern_zschernack.pdf" target="_blank">Mein Profil mit Lebenslauf</a>
               </ListGroup.Item>
          
             </ListGroup>
             
            </Col>
          </Row>
        </Container>
    )
 
}

export {Downloads}

