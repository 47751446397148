import { FunctionComponent, ReactElement } from "react";

const Tab: FunctionComponent = (props): ReactElement => {
    return (
        <>{props.children}</>    
    )
}

export {Tab}


