import React, { useEffect, useState } from 'react';

import { Frontend } from './Frontend/Frontend';

import './bootstrap.css';

import './app.css';

const App = (props) => {

    return (
        <Frontend />
    )
}

export {App}

