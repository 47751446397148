import { FunctionComponent, ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup  from 'react-bootstrap/ListGroup';
import Button  from 'react-bootstrap/Button';
import { Refs } from '../Frontend/types/Refs';

interface SingleEntryProps {
    entry: Refs,
}

const SingleEntry: FunctionComponent<SingleEntryProps> = ({entry}): ReactElement => {


    const Works = entry.work.map(
        single => {
            return <ListGroup.Item>{single.title}</ListGroup.Item>
        }
    );

    return (
        <Card className="alert alert-secondary p-0">
    
            <Card.Body>
                <Card.Title>
                    <h2>{entry.name}</h2></Card.Title>
                <Card.Text><hr/>
                <i>{entry.period}</i><hr />
                    {entry.content}

                   
                    <Card className="innercard alert alert-warning p-0">
                        <Card.Body>
                            <Card.Title>Arbeiten</Card.Title>
                            <ListGroup className="work">
                                    {Works}
                            </ListGroup>
                        </Card.Body>
                    </Card>    

                    <Card className="innercard alert alert-warning p-0">
                        <Card.Body>
                            <Card.Title>Skills</Card.Title>
                            <Card.Text>
                                    {entry.skills}
                            </Card.Text>
                        </Card.Body>
                    </Card>    
                   

                </Card.Text>
            <Button href={entry.url} target="_blank" className="btn btn-warning">Live ansehen</Button>
            </Card.Body>
        </Card>
    )
}     

export {SingleEntry}  