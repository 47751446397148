
import { Refs } from '../Frontend/types/Refs';


/** using fetch for special meta query */
export const FetchData = ():Promise<Refs[]> => {
    return new Promise((resolve, reject) => {
        const fetchUrl = 'https://us-central1-restapiwithdatabase.cloudfunctions.net/app/posts'; // Node express api
           fetch(fetchUrl, {
                "credentials": 'same-origin',
                "method": 'get',
            })
            .then(response => response.json())
            .then(data => {
                console.log('data',data)    
                resolve(data.reverse());
            }).catch(err => {
                reject(err);
            });
        });

}

