import {FunctionComponent, ReactElement} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';


interface HeaderProps {
    toggleTab: (number) => void
}

const Header:FunctionComponent<HeaderProps> = ({toggleTab}): ReactElement => {
    
    return(
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
        <Navbar.Brand href="#" onClick={() =>{toggleTab(0)}}>Profil Björn Zschernack</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" id="homelink" onClick={() =>{toggleTab(0)}}>Home</Nav.Link>
            <Nav.Link href="#refs"  id="reflink" onClick={() =>{toggleTab(1)}}>Referenzen</Nav.Link>
            <Nav.Link href="#downloads"  id="downloadlink" onClick={() =>{toggleTab(2)}}>Downloads</Nav.Link>
          </Nav>
         
        </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}

export {Header}